import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import FullAPI from '../../api.constants';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { PATHS } from '../../app.constants';

/***
 * This class is just the base for `AuthInterceptor`,
 * which is the one to be used as Provider in module instead.
 */
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	private readonly router = inject(Router);
	private readonly injector = inject(Injector);

	private isPublic(operation: string, method: string): boolean {
		if (operation.startsWith(PATHS.assets)) {
			return true;
		}
		const pub_list = Object.values(FullAPI)
			.find(op => `${environment.API.endpoint}${op.endp}` === operation && op.meth === method);
		return pub_list?.pub ?? false;
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authProvider = this.injector.get(AuthService);

		let authReq = req;
		// If operation requested is not public
		if (!this.isPublic(req.url, req.method)) {
			// Require authentication details
			const sessid = authProvider.localSession.toString();
			const selfid = authProvider.localUser.id.toString();
			// Get the auth header from the service.
			const authHeader = new HttpHeaders()
				.set('Authorization', `Bearer ${selfid}s${sessid}`);
			// Clone the request to add the new header.
			authReq = req.clone({ headers: authHeader });
		}

		return next.handle(authReq).pipe(
			tap({
				error: error => {
					if (error instanceof HttpErrorResponse && error.status === 401) {
						authProvider.killSession();
						this.router.navigate(['/login']);
					}
				}
			})
		);
	}

}

export const AuthInterceptor = {
	provide: HTTP_INTERCEPTORS,
	useClass: AuthHttpInterceptor,
	multi: true
};
